import React, { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import SRModal from '../../components/SRModal'
import { _toast } from 'src/services/utils';
import moment from 'moment';
import {
  CButton, CDropdown, CDropdownToggle, CBadge, CDropdownMenu, CDropdownItem
} from '@coreui/react';

import AuthService from 'src/services/authServices';
import SRConfirmModal from 'src/components/SRConfirmModal';
import GModal from 'src/components/GModal';

const ActionsView = ({ item, index, ...props }) => {

  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [spnModal, setSpnModal] = useState(false);

  return (

    <td className="py-2">
      <td className="py-2">
        <CDropdown direction="down" >
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar">
              <CBadge color={"success"} >Actions</CBadge>
            </div>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem onClick={() => {
              setConfirmModal(true);
            }
            }>
              <CIcon name="cil-settings" className="mfe-2" />
              Confirm
            </CDropdownItem>
            <CDropdownItem onClick={() => {
              setModal(true);
            }
            }>
              <CIcon name="cil-trash" className="mfe-2" />
              Cancel
            </CDropdownItem>
            <CDropdownItem divider />

            <CDropdownItem onClick={() => {
              setSmsModal(true);
            }
            }>
              <CIcon name="cil-bell" className="mfe-2" />
              Send SMS
            </CDropdownItem>
            <CDropdownItem onClick={() => {
              setSpnModal(true);
            }
            }>
              <CIcon name="cil-warning" className="mfe-2" />
              Push Notification
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>

      </td>
      {confirmModal &&
        <>
          <SRConfirmModal
            modal={confirmModal}
            setModal={setConfirmModal}
            serviceRequest={item}
          />
        </>
      }
      {modal &&
        <>
          <SRModal
            title={'Cancel'}
            message={'Are you sure you want to cancel this request'}
            item={item}
            color={'danger'}
            modal={modal}
            setModal={setModal}
            okTilte={'Cancel Request'}
            okAction={() => {
              AuthService.createRequest(
                'POST',
                'ServiceRequest/updaterequeststatus',
                null,
                JSON.stringify({
                  RequestID: item.ID,
                  StatusID: 5
                }),
              )
                .then((response) => response.json())
                .then((response) => {
                  if (response.statuscode != 200) {
                    _toast(`Something Went Wrong ${response.statusmessage}`, 'error');
                    return;
                  }
                  _toast(response.statusmessage, 'success');
                  window.location.assign(`../../#/ServiceRequests/CancelledRequests/`);
                }).catch((e) => {
                  _toast(e, 'error');
                });
            }}
            cancelTitle={'Close'}
            cancelAction={() => {
              setModal(false)
            }}
          />
        </>
      }
      {smsModal &&
        <>
          <GModal
            title={'Send SMS'}
            size={'lg'}
            item={
              {
                "SMSReceiver": item.PhoneNumber,
                "SMSLang": "e",
                "SMSText": "Hello from Hosptialia"
              }
            }
            color={'success'}
            modal={smsModal}
            inputs={
              [
                { id: 'SMSReceiver', label: 'PhoneNumber', type: 'phone' },
                { id: 'SMSText', label: 'text', type: 'text' },
              ]}
            setModal={setSmsModal}
            okTilte={'Send SMS Message'}
            cancelTitle={'Close'}
            okEndPoint={{ method: 'POST', endPoint: 'mng/sendsms' }}
          />
        </>
      }
      {spnModal &&
        <>
          <GModal
            title={'Send Push Notification'}
            size={'lg'}
            item={
              {
                "Title": "Title",
                "Message": "Message",
                "ID": item.ID
              }
            }
            color={'success'}
            modal={spnModal}
            inputs={
              [
                { id: 'Title', label: 'PhoneNumber', type: 'phone' },
                { id: 'Message', label: 'text', type: 'text' },
              ]}
            setModal={setSpnModal}
            okTilte={'Send Push Notification'}
            cancelTitle={'Close'}
            okEndPoint={{ method: 'POST', endPoint: 'mng/SendFirebase' }}
          />
        </>
      }

    </td>
  )
}
const NewServiceRequestsSeeder = {
  scopedSlots: {
    'StatusTimeStamp': (item)=>{
      return (
        <td className='text-center'>
          {moment.utc(item.StatusTimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
        </td>
    )
    },
    'Location':
      (item) => (
        <td>
          <CIcon name="cil-location-pin" size="2xl" onClick={() => {
            window.open(`https://maps.google.com/?q=${item.Location_Lat},${item.Location_Long}`, '_blank');

          }
          } />
        </td>
      ),
    'Actions':
      (item, index) => {
        return (
          <ActionsView item={item} index={index}></ActionsView>
        )
      },
    'PayementMethod':
      (item) => (
        <td>
          <CIcon name={item.PayementMethod == 2 ? "cil-credit-card" : "cil-dollar"} size="2xl" />
        </td>
      ),
    'PromoCodeTitle':
      (item) => (
        <td className='text-center'>
          {item.PromoCodeTitle ? item.PromoCodeTitle : '-----'}
        </td>
      ),
    'location_type':
      (item) => (
        <td>
          <CIcon name={item.location_type == 2 ? "cil-phone" : "cil-home"} size="2xl" />
        </td>
      ),

  },
  fields: [
    { key: 'StatusTimeStamp', label: 'Timestamp' },
    'ServiceType', 'Speciality',
    { key: 'ClientFullName', label: 'Name' },
    'PhoneNumber',
    { key: 'CurrentConditionDescription', label: 'Description' },
    'Fees',
    'PromoCodeTitle',
    , 'SelectedDate',
    'SelectedTime',
    { key: 'location_type', label: 'Location Type' },
    'Address', 'Location',
    "PayementMethod",
    , 'Actions'],

  intialTableParams: {
    page: 1,
    pages: 1,
    length: 10
  },

  params: {},

  dataendPoint: `ServiceRequest/getallnew`,
  dataendPointMethod: 'POST',
  headline: 'New Service Requests'



};

export default NewServiceRequestsSeeder;