
 import React, { useState, useEffect } from 'react'; 
 import CIcon from '@coreui/icons-react'
 import GModal from '../../components/GModal'
 import {_toast} from 'src/services/utils';

 import {
    CButton,CSwitch
    
  } from '@coreui/react';
import AuthService from 'src/services/authServices';

const IsActiveView = ({item,index,...props}) => {
  const [checked, setChecked] = React.useState(item.isActive);
  useEffect(() => {
    setChecked(item.isActive);
  }, [item.isActive]);
  return (
    <td>
    <CSwitch
            className="mr-1"
            color="success"
            checked={checked} 
            shape="pill"
            onChange={()=>{
              AuthService.createRequest(
                'POST',
                  `customers/changeStatus?id=${item.Id}`,
                  null,
                  JSON.stringify({
                  }),
                )
                  .then((response) => response.json())
                  .then((response) => {
                      if (response.statuscode!=200){
                          _toast(`Some thing Went Wrong ${response.statusmessage}`,'error');
                          return;
                      }
                      _toast(response.statusmessage,'success');
                      setChecked(!checked);
                      item.isActive=!item.isActive;
                  }).catch((e)=>{
                      _toast(e,'error');
                   });
              
            }}
          />         
  </td>
    )
  

}
const ActionsView = ({item,index,...props}) => {
  const [modal, setModal] = useState(false);
  
  return (
    <td className="py-2">
                <CButton
                  color="primary"
                  shape="pill"
                  size="sm"
                  onClick={()=>{
                      setModal(true);
                    }
                    }
                >
                SMS
                </CButton>
                <GModal 
                    title={'Send SMS'}
                    size ={'lg'}
                    item={
                      {
                        "SMSReceiver": item.PhoneNumber,
                        "SMSLang": "e",
                        "SMSText": "Hello from Hosptialia"
                      }
                    }
                    color={'success'}
                    modal={modal}
                    inputs={
                      [
                      {id:'SMSReceiver' , label:'PhoneNumber', type:'phone'},
                      {id:'SMSText' , label:'text', type:'text'},
                      ]}
                    setModal={setModal}
                    okTilte={'Send SMS Message'}
                    cancelTitle={'Close'}
                    okEndPoint= {{method:'POST', endPoint:'mng/sendsms'}}
                  />
              </td>
  )
}
 const CustomerListSeeder = {
    scopedSlots : {
        'isSmoker':
          (item)=>(
            <td>
                <CIcon  name={item.isSmoker== false?"cil-x-circle":"cil-check-circle"} size="2xl"/>
            </td>
          ),
          'isAlcoholic':
          (item)=>(
            <td>
                <CIcon  name={item.isAlcoholic== false?"cil-x-circle":"cil-check-circle"} size="2xl"/>
            </td>
          ),
          'CutomerBloodType':
          (item)=>(
            <td>
               {item.CutomerBloodType==null?'None':item.CutomerBloodType}
            </td>
          ),
          'isActive':
          (item, index)=>{
            return (
              <IsActiveView item={item} index={index}></IsActiveView>
            )
          },
          'Actions':
          (item, index)=>{
            return (
                <ActionsView item={item} index={index}></ActionsView>
              )
          }
      
      },
     fields : [
        {key: 'CustomerName',label: 'Name'},
        {key: 'CustomerAge',label: 'Age'},
        {key: 'PhoneNumber',label: 'PhoneNumber'},
        {key: 'isMale',label: 'Gender'},
        {key: 'CutomerBloodType',label: 'Blood Type'},
        {key: 'Height',label: 'Height'},
        {key: 'Weight',label: 'Weight'},
        {key: 'isSmoker',label: 'Smoker ?'},
        {key: 'isAlcoholic',label: 'Alchoholic ?'},
        {key: 'isActive',label: 'Active?'},
        'Actions'
     ],
            
     intialTableParams:{
        page:1,
        pages:1,
        length:10
        },
        
      params : {},
      
     dataendPoint: `customers/getall`,
     dataendPointMethod: 'POST',
     headline: 'Customer List'
      
      

};

export default CustomerListSeeder;