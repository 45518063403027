import React from 'react'
import CIcon from '@coreui/icons-react'
const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
    badge: {
      color: 'info',
      text: 'NEW',
    }
  },
  
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Operations']
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Service Types',
    route: '/base',
    icon: 'cil-options',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Service Types',
        to: '/ServiceTypes/ServiceTypes',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Specialites',
        to: '/ServiceTypes/Specialities',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Service Providers',
        to: '/ServiceProviders/Index',
      }
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Service Requests',
    route: '/base',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'New Requests',
        to: '/ServiceRequests/NewRequests',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Request Confirmed',
        to: '/ServiceRequests/ConfirmedRequests',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Request Completed',
        to: '/ServiceRequests/CompletedRequests',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Request Rated',
        to: '/ServiceRequests/RatedRequests',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Request Cancelled',
        to: '/ServiceRequests/CancelledRequests',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Call Requests',
    route: '/base',
    icon: 'cil-phone',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Pending Calls',
        to: '/ServiceRequests/CallRequests',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Completed Calls',
        to: '/ServiceRequests/CompletedCallRequests',
      },

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Customers',
    icon: 'cil-people',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Customers List',
        to: '/Customers/Index',
        
      }

    ],
  },
   {
    _tag: 'CSidebarNavDropdown',
    name: 'Installment',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Service List',
        to: '/installment/services/index',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Center List',
        to: '/installment/centers/index',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Operations List',
        to: '/installment/Operations/index',
        
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Operations Availabity',
        to: '/installment/Operations/availabilty',
        
      },{
        _tag: 'CSidebarNavItem',
        name: 'Requests List',
        to: '/installment/requests/index',
        
      }



    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Adminstrators',
    icon: 'cil-people',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Adminstrator List',
        to: '/Users/Index', 
      }

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Notifications',
    icon: 'cil-bell',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Backend Notifications',
        to: '/Management/BackendNotifications', 
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Push Notifications',
        to: '/Management/PushNotifications', 
      }

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Configurations',
    icon: 'cil-settings',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Slider Images',
        to: '/Attachrments/Index', 
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Promo Codes',
        to: '/PromoCodes/Index', 
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Terms & Conditions',
        to: '/Management/TermsAndConditions', 
      }

    ],
  },
  
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  },
  
]

export default _nav
