import {toast} from 'react-toastify';


export const _toast = (message,status) => {
    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false
    }

    switch (status) {
        case 'info':
            toast.info(message,options);
            break;
        case 'warning':
            toast.warn(message,options);
            break;
        case 'error':
            toast.error(message,options);
            break;
        case 'success':
            toast.success(message,options);
            break;
        default:
            toast(message,options);
            break;
    }
}