import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import AuthService, { isAuthenticated } from 'src/services/authServices';
import { getToken, onMessageListener } from './firebase';
import { ToastContainer } from 'react-toastify';
import { _toast } from 'src/services/utils';
import { connect } from 'react-redux';
import * as actions from 'src/store/actions';
import {TheSidebar} from './adminPanel_containers';
import { Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { notAuthGuard, authGuard } from './routes/guards';
import firebase from 'firebase/app';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./adminPanel_containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

// Access store required states
const mapStateToProps = (state) => {
  return {
    loggedIn: state.common.loggedIn
  };
};
// Access store required actions
const mapDispatchToProps = (dispatch) => {
  return {
    updateNotifications: () => dispatch(actions.getNotifications())
  };
};

class App extends Component {
  state = {
    currentNotification: null,
    tokenFound: false,
    fcmToken: null
  }

  setToken(found, token) {
    if (found) {
      this.setState({
        ...this.state,
        tokenFound: true,
        fcmToken: token
      });
      const payload = JSON.stringify({ FCM: token, TopicListName: 'HospitaliaBE' });
      this.registerPushListener();
      AuthService.
        createRequest('POST', 'mng/registerFCM', null, payload)
        .then();
    } else {
      _toast('please allow Notification permission', 'warning');
    }
  }
  registerPushListener() {
    // background listener
    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      console.log(data);
    }
    );
    // foreground listener
    onMessageListener().then(payload => {
      console.log(payload);
      _toast(payload.notification.body, 'info');
      this.setState({ ...this.state, currentNotification: payload.notification })
    });
  }
  initFCM() {
    if (firebase.messaging.isSupported()) {
      getToken(this.setToken.bind(this));
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn != this.props.loggedIn) {
      this.initFCM();
    }
  }
  componentDidMount() {
    console.log(process.env);
    if (isAuthenticated()) {
      this.initFCM();
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
        { isAuthenticated() ? <div className="c-app c-default-layout">
            <TheSidebar />
            <GuardProvider guards={[authGuard, notAuthGuard]}>
              <Switch>
                <GuardedRoute path="/" name="Home" render={props => <TheLayout {...props} />} />
                <GuardedRoute exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              </Switch>
            </GuardProvider>
          </div> : (
            <GuardProvider guards={[authGuard, notAuthGuard]}>
              <Switch>
                <GuardedRoute meta={{ auth: false, notAuth: true }} exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Redirect from="/" to="/login" />
              </Switch>
            </GuardProvider>
          )
        }
        </React.Suspense>
        <ToastContainer />
      </HashRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
