import * as actiontypes from "src/store/actions/actionTypes";
import AuthService from 'src/services/authServices';

export const setSlider = (value) => {
  return {type: actiontypes.SET_SLIDER, sidebarShow: value};
}
export const setLoginSuccess = () => {
  return {type: actiontypes.LOGIN_SUCCESS};
}
export const getTermsAndConditionsSuccess = (data) => {
  return {type: actiontypes.TERMS_CONDITIONS,termsAndConditions:data};
}
export const getTermsAndConditions = () => {
  return (dispatch) => {
      AuthService.createRequest(
          'GET',
            'mng/TCadmin',
            null,
            null,
          )
          .then((response) => response.json())
          .then((response) => {
          dispatch(getTermsAndConditionsSuccess(response.responseobject))
          });
  }
}