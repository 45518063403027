import React, { useState, useEffect } from 'react'; 
import CIcon from '@coreui/icons-react'
import {
  CContainer,CRow,CCol,CButton,CSpinner
  
} from '@coreui/react';
import {_toast} from 'src/services/utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useDispatch,useSelector} from 'react-redux';
import * as actions from 'src/store/actions' ;
import AuthService from 'src/services/authServices'

const TermsAndConditions = (props) => {
  const dispatch = useDispatch();
  const termsAndConditions = useSelector(state => state.common.termsAndConditions);
  const [enEditor,setEnEditor] = useState(null);
  const [arEditor,setArEditor] = useState(null);
  const [changed,setChanged] = useState(false);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    dispatch(actions.getTermsAndConditions());
   }, [dispatch]);
  return (
    <div className="termsAndConditions">
       <CContainer>
          {termsAndConditions && <CRow>
            <CCol xs="12" md="6" className="mb-4">
              <CKEditor
                    editor={ ClassicEditor }
                    config={{  
                      toolbar: ['clipboard','undo','heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                        'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                    }} 
                    data={termsAndConditions.Content_EN}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        setEnEditor(editor);
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setChanged(true);
                      console.log( 'Change.' );
                    }}
                    onBlur={ ( event, editor ) => {
                      const data = editor.getData();
                      console.log( 'Blur.' );
                    } }
                    onFocus={ ( event, editor ) => {
                    } }
                /></CCol>
            <CCol xs="12" md="6" className="mb-4">
              <CKEditor
                    editor={ ClassicEditor }
                    config={{  
                      toolbar: ['clipboard','undo','heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                        'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                    }} 
                    data={termsAndConditions.Content_AR}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        setArEditor(editor);
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setChanged(true);
                      console.log( 'Change.');
                    } }
                    onBlur={ ( event, editor ) => {
                      const data = editor.getData();
                      console.log( 'Change.');
                    } }
                    onFocus={ ( event, editor ) => {
                    } }
                /></CCol>
          </CRow>}
          {termsAndConditions && (
            <CRow>
              <CCol xs="12" className="text-center mt-4">
                <CButton  disabled={!changed || loading} color="success" size="lg" onClick={()=>{
                  const payload = {
                    Content_AR : arEditor.getData(),
                    Content_EN : enEditor.getData(),
                    Id:termsAndConditions.Id
                  };
                  setLoading(true);
                  AuthService.createRequest(
                    "POST",
                    "mng/updatecontent",
                      null,
                      JSON.stringify(payload),
                    )
                      .then((response) => response.json())
                      .then((response) => {
                        setLoading(false);
                        if (response.statuscode!=200){
                            _toast(`Something Went Wrong ${response.statusmessage}`,'error');
                            return;
                        }
                        _toast(`updated successfully`,'success');
                      }).catch((e)=>{
                          setLoading(false);
                          _toast(e,'error');
                       });
                }} className="px-4">
                  {loading && <CSpinner size="sm" />} Save
                </CButton>
              </CCol>
            </CRow>
          )}
        </CContainer>
    </div>
  )
}

export default TermsAndConditions
