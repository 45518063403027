import {isAuthenticated} from 'src/services/authServices';
export const authGuard = (to, from, next) => {
    if (to.meta.auth) {
      if (isAuthenticated()) {
        next();
      }
      next.redirect('/login');
    } else {
      next();
    }
};
export const notAuthGuard = (to, from, next) => {
    if (to.meta.notAuth) {
        if (!isAuthenticated()) {
        next();
        }
        next.redirect('/dashboard');
    } else {
        next();
    }
};