import React , {useEffect,useState} from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { FaPlusCircle,FaPhoneAlt,FaRegStar } from "react-icons/fa";
import AuthService  from 'src/services/authServices';
import {_toast} from 'src/services/utils';
import {useDispatch,useSelector} from 'react-redux';
import * as actions from 'src/store/actions' ;
import {isAuthenticated} from 'src/services/authServices';
import {useHistory} from 'react-router-dom';
const notificationIcon = {
  1 : <FaPlusCircle size="2em" color="red"/>,
  4 : <FaRegStar size="2em" color="green" />,
  6 : <FaPhoneAlt size="2em" color="orange" />
};
const TheHeaderDropdownNotif = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSelector(state => state.notifications.notifications);
  const navigate = (notification)=> {
    if(notification.NotificationType == 1 ) {
      history.push('/ServiceRequests/NewRequests');
    }else if (notification.NotificationType ==4) {
      history.push('/ServiceRequests/RatedRequests');
    }else if(notification.NotificationType ==6) {
      history.push('/ServiceRequests/CallRequests');
    }
  }
  const loadData = () => {
    dispatch(actions.getNotifications());
  }
  useEffect(() => {
   if(isAuthenticated())loadData();
  }, [dispatch]);
  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell"/>
        <CBadge shape="pill" color="danger">{notifications.length}</CBadge>
      </CDropdownToggle>
      <CDropdownMenu  placement="bottom-end" className="noti-deopdown-menu pt-0">
        <CDropdownItem
          header
          tag="div"
          className="text-center"
          color="light"
        >
          <strong>You have <span style={{color:'#e55353'}}>{notifications.length}</span> new notifications</strong>
        </CDropdownItem>
        <div style={{maxHeight:300,overflowY:'auto'}}>
          {notifications.length > 0 ? notifications.map((item)=>{
            return(
            <CDropdownItem onClick={()=>{dispatch(actions.readNotification(item));navigate(item);}} className="noti-dropdown-item" key={item.ID}>
                <div className="noti-icon">{notificationIcon[item.NotificationType]}</div>
                <div className="noti-message">
                  <p className="noti-title">{item.Title}</p>
                  <p className="noti-body">{item.Body}</p>
                </div>
            </CDropdownItem>
            );
          }):(<div style={{padding:10}} className="text-center">No Notifications found</div>)}
        </div>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownNotif