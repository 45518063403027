
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import SRModal from '../../components/SRModal'
import GModal from '../../components/GModal'
import {
  CBadge,
  CSpinner,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from '@coreui/react'
import {
  CButton, CLink,

} from '@coreui/react';
import AuthService from 'src/services/authServices';
import { _toast } from 'src/services/utils';
import moment from 'moment';
const ActionsView = ({ item, index, ...props }) => {
  const [modal, setModal] = useState(false);
  const [pModal, setPModal] = useState(false);


  return (

    <td className="py-2">
      <CDropdown direction="down" >
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="c-avatar">
            <CBadge color={"success"} >Actions</CBadge>
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          {
            item.PaymentLink &&

            <CDropdownItem onClick={() => {
              setPModal(true);
            }
            }>
              <CIcon name="cil-settings" className="mfe-2" />
              Send Payment Link
            </CDropdownItem>
          }
          <CDropdownItem divider />
          <CDropdownItem onClick={() => {
            setModal(true);
          }
          }>
            <CIcon name="cil-lock-locked" className="mfe-2" />
            Complete
          </CDropdownItem>

        </CDropdownMenu>
      </CDropdown>

      <GModal
        title={'Send Payment Link'}
        size={'lg'}
        item={
          {
            "SMSReceiver": item.PhoneNumber,
            "SMSLang": "e",
            "SMSText":
              `Hello ${item.ClientFullName}, 
                         Kindly use the link below to pay for the requessted service
                        ${item.PaymentLink}
            `
          }
        }
        color={'success'}
        modal={pModal}
        inputs={
          [
            { id: 'SMSReceiver', label: 'PhoneNumber', type: 'phone' },
            { id: 'SMSText', label: 'text', type: 'textarea' },
          ]}
        setModal={setPModal}
        okTilte={'Send SMS Message'}
        cancelTitle={'Close'}
        okEndPoint={{ method: 'POST', endPoint: 'mng/sendsms' }}
      >
      </GModal>
      <SRModal
        title={'Complete'}
        message={'Are you sure you want to complete this request'}
        item={item}
        color={'warning'}
        modal={modal}
        setModal={setModal}
        okTilte={'Complete'}
        okAction={() => {
          AuthService.createRequest(
            'POST',
            'ServiceRequest/updaterequeststatus',
            null,
            JSON.stringify({
              RequestID: item.ID,
              StatusID: 3
            }),
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.statuscode != 200) {
                _toast(`Something Went Wrong ${response.statusmessage}`, 'error');
                return;
              }
              _toast(response.statusmessage, 'success');
              window.location.assign(`../../#/ServiceRequests/CompletedRequests/`);
            }).catch((e) => {
              _toast(e, 'error');
            });
        }}
        cancelTitle={'Close'}
        cancelAction={() => {
          setModal(false)
        }}
      ></SRModal>
    </td>
  )
}
const GeneratePaymentLink = ({ item, index, ...props }) => {
  const [link, setLink] = useState(item.PaymentLink);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  return (
    <td>
      {
        link && <CLink href={link} target="_blank">Link</CLink>
      }
      {
        !link && item.PayementMethod == 2 && <CButton color="info" size='sm' onClick={
          () => {
            setLoading(true)
            AuthService.createRequest('POST', 'Accept/generatePaymentLink', [],
              JSON.stringify({ RequestID: item.ID })).then((response) => response.json())
              .then((response) => {
                if (response.statuscode != 200) {
                  _toast(response.responsemessage, 'error');
                }
                item.PaymentLink = response.responseobject;
                setLink(response.responseobject);
              }).catch((e) => {
                _toast(e, 'error');
              }).finally((e) => {
                setLoading(false)
                history.go(0);
              });
          }
        }>Generate</CButton>
      }
      {
        loading && <CSpinner color="success" size="sm" />
      }


    </td>
  )
}



const ConfirmedServiceRequestsSeeder = {
  scopedSlots: {
    'StatusTimeStamp': (item)=>{
      return (
        <td className='text-center'>
          {moment.utc(item.StatusTimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
        </td>
    )
    },
    'Location':
      (item) => (
        <td>
          <CIcon name="cil-location-pin" size="2xl" onClick={() => {
            window.open(`https://maps.google.com/?q=${item.Location_Lat},${item.Location_Long}`, '_blank');

          }
          } />
        </td>
      ),
    'Actions':
      (item, index) => {
        return (
          <ActionsView item={item} index={index}></ActionsView>
        )
      },
    'PayementMethod':
      (item) => (
        <td>
          <CIcon name={item.PayementMethod == 2 ? "cil-credit-card" : "cil-dollar"} size="2xl" />
        </td>
      ),
    'PaymentLink':
      (item) => (
        <GeneratePaymentLink item={item}></GeneratePaymentLink>

      )
    ,
    'PromoCodeTitle':
      (item) => (
        <td className='text-center'>
          {item.PromoCodeTitle ? item.PromoCodeTitle : '-----'}
        </td>
      ),
    'location_type':
      (item) => (
        <td>
          <CIcon name={item.location_type == 2 ? "cil-phone" : "cil-home"} size="2xl" />
        </td>
      ),
  },
  fields: [
    { key: 'StatusTimeStamp', label: 'Timestamp' },
    'ServiceType', 'Speciality',
    { key: 'ServiceProviderName', label: 'ServiceProvider' },
    { key: 'ClientFullName', label: 'Name' },
    'PhoneNumber',
    { key: 'CurrentConditionDescription', label: 'Description' },
    'Fees',
    'PromoCodeTitle',
    , 'SelectedDate', 'SelectedTime',
    'Address',
    { key: 'location_type', label: 'Location Type ' },
    'Location',
    "PayementMethod",
    "PaymentLink"
    , 'Actions'],


  intialTableParams: {
    page: 1,
    pages: 1,
    length: 10
  },

  params: {},

  dataendPoint: `ServiceRequest/getallconfirmed`,
  dataendPointMethod: 'POST',
  headline: 'Confirmed Service Requests'



};

export default ConfirmedServiceRequestsSeeder;