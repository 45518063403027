
 import React, { useState, useEffect } from 'react'; 
 import SRModal from '../../components/SRModal'
 import {_toast} from 'src/services/utils';
 import {
    CButton,
    
  } from '@coreui/react';
import AuthService from 'src/services/authServices';
import GModal from 'src/components/GModal';
import moment from 'moment';
const ActionsView = ({item,index,...props}) => {
    const [modal, setModal] = useState(false);

  return (
   
    <td className="py-2">
                <CButton
                  color="primary"
                  shape="pill"
                  size="sm"
                  onClick={()=>{
                      setModal(true);
                    }
                    }
                >
                Complete
                </CButton>
                <SRModal 
                        title={'Complete'}
                        message= {'Are you sure you want to complete this request'}
                        item={item}
                        color={'warning'}
                        modal={modal}
                        setModal={setModal}
                        okTilte={'Complete'}
                        okAction={()=>{
                            AuthService.createRequest(
                                'POST',
                                  'ServiceRequest/updatecallrequest',
                                  null,
                                  JSON.stringify({
                                    RequestID:item.ID,
                                  }),
                                )
                                  .then((response) => response.json())
                                  .then((response) => {
                                      if (response.statuscode!=200){
                                          _toast(`Some thing Went Wrong ${response.statusmessage}`,'error');
                                          return;
                                      }
                                      _toast(response.statusmessage,'success');
                                      window.location.assign(`../../#/ServiceRequests/CompletedCallRequests/`);
                                  }).catch((e)=>{
                                    _toast(e,'error');
                                   });
                        }}
                        cancelTitle={'Close'}
                        cancelAction={()=>{
                            setModal(false)}}
                         ></SRModal>
              </td>
  )
}
 const  PendingCallRequestsSeeder = {
    scopedSlots : {
          'TimeStamp': (item)=>{
            return (
              <td className='text-center'>
                {moment.utc(item.TimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
              </td>
          )
          },
          'Actions':
          (item, index)=>{
            return (
                <ActionsView item={item} index={index}></ActionsView>
              )
          }
      
      },
     fields : [
        {key: 'TimeStamp',label: 'TimeStamp'},
        {key: 'ClientName',label: 'Name'},
        'PhoneNumber',
        'Actions'],
      
      
     intialTableParams:{
        page:1,
        pages:1,
        length:10
        },
        
      params : {},
      
     dataendPoint: `ServiceRequest/getallcallrequests`,
     dataendPointMethod: 'POST',
     headline: 'Call Requests', 
     TableActions : function(props) {
      const [addModal, setAddModal] = useState(false);

     return (
       <>
        <div className="card-header-actions">
            <CButton color = 'primary' onClick = {()=>{
                setAddModal(true);
            }}> Add New Call Request</CButton>
        </div>
          <GModal 
          title={'Add Call Request'}
          size ={'lg'}
          item={
            {
              ClientName: 'Client Name',
              PhoneNumber: '01111111111',
            }
          }
          color={'success'}
          modal={addModal}
          inputs={
            [
            {id:'ClientName' , label:'ClientName', type:'text'},
            {id:'PhoneNumber' , label:'PhoneNumber', type:'text'},
            ]}
          setModal={setAddModal}
          okTilte={'Add'}
          cancelTitle={'Close'}
          okEndPoint= {{method:'POST', endPoint:'ServiceRequest/admincallrequest'}}
  
            >
         </GModal>
         </>
      )
    }

};

export default PendingCallRequestsSeeder;