
import { _toast } from 'src/services/utils';
class AuthService {
    static beURL = process.env.REACT_APP_API_BASE_URL;

    static async createRequest(method, endpoint, headers, data) {
        const requestOptions = {
            method: method,
            headers:
            {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                'RNFB-Response': 'utf8',
                'RNFB-Request': 'utf8',
                'Content-type': 'application/json',
                country: localStorage.getItem('CountrySelected')
            },
            body: (method == 'GET') ? null : data
        };
        try {
            let response = await fetch(`${this.beURL}${endpoint}`, requestOptions);
            if (response.status == 401) {
                this.logout();
            }
            return response;
        } catch (e) {
            _toast(e, 'error');
            this.logout();
        }
    }
    static async logout() {
        localStorage.clear();
        window.location.reload();
    }
    static async Upload(method, endpoint, headers, data) {
        const requestOptions = {
            method: method,
            headers:
            {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                'RNFB-Response': 'utf8',
                'RNFB-Request': 'utf8',
                country: localStorage.getItem('CountrySelected')
            },
            body: (method == 'GET') ? null : data
        };

        let response = await fetch(`${this.beURL}${endpoint}`, requestOptions);
        return response;
    }


}
export const isAuthenticated = () => {
    return localStorage.getItem('AccessToken') ? true : false;
}
export default AuthService;