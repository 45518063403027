
import moment from "moment";
 const  CompletedCallRequestsSeeder = {
        scopedSlots : {
          'TimeStamp': (item)=>{
            return (
              <td className='text-center'>
                {moment.utc(item.TimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
              </td>
          )
          }
      },
      fields : [
        {key: 'TimeStamp',label: 'TimeStamp'},
        {key: 'ClientName',label: 'Name'},
        'PhoneNumber',
        {key: 'ReadByName',label: 'Updated By'}],


      intialTableParams:{
        page:1,
        pages:1,
        length:10
        },
        
      params : {},

      dataendPoint: `ServiceRequest/getallfullfilledcallrequests`,
      dataendPointMethod: 'POST',
      headline: 'Completed Call Requests'
};

export default CompletedCallRequestsSeeder;