import React, { useState, useEffect } from 'react'; 

import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle
  
} from '@coreui/react'


const SRModal = ({item, title, message,modal,setModal,color,okTilte,okAction,cancelTitle,cancelAction,...props}) => {

  return (
   
    <CModal 
        show={modal} 
        onClose={setModal}
        color={color}
        {...props}
    >
        <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
        {message}
        </CModalBody>
        <CModalFooter>
        <CButton color={color} onClick={okAction}>{okTilte}</CButton>{' '}
        <CButton 
            color="secondary" 
            onClick={cancelAction}
        >{cancelTitle}</CButton>
        </CModalFooter>
    </CModal>
  )
}

export default SRModal
