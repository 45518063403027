import firebase from 'firebase/app';
import 'firebase/messaging';
const firebaseConfig = {
    apiKey: "AIzaSyBjUpV5HY__hFIFMeLrvK_SjrFjfT3ov_A",
    authDomain: "hospitalia-277419.firebaseapp.com",
    databaseURL: "https://hospitalia-277419.firebaseio.com",
    projectId: "hospitalia-277419",
    storageBucket: "hospitalia-277419.appspot.com",
    messagingSenderId: "212918255358",
    appId: "1:212918255358:web:e8a482372fd2cd35c52faf",
    measurementId: "G-K54RSHRCT4"
};
let messaging;
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else{
  firebase.app();
}
if(firebase.messaging.isSupported()){
  messaging = firebase.messaging();
}

export const getToken = (setTokenFound) => {
    return messaging.getToken({vapidKey: 'BG5xVw9Zk1ZM0E0YZEwlR6SYjwcD_UnYXz8q_rJi24SxT52ZQc8HAlX939hUdxLNDc4qlRRNZJaWTOGY6s14Q0M'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true,currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false,null);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      setTokenFound(false,null);
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});
