import React, { useState, useEffect } from 'react'; 
import { useHistory, useLocation } from 'react-router-dom'
import {
  CButton,
  CSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLabel,
  CInput,
  CForm,
  CTextarea,
  CNumb
  

} from '@coreui/react'
import AuthService from 'src/services/authServices';
import {_toast} from 'src/services/utils';



const GModal = ({inputs,item, title, message,modal,setModal,color,okTilte,cancelTitle,okEndPoint,...props}) => {
  const history = useHistory();
  const[ formParams, setFormParams]= useState(item);
 // console.log(item);
  useEffect(() => {
    if (formParams!=item)
        setFormParams(item);
    console.log(item.ID)
  }, [item,item.ID]);
  return (
    <>
    <CModal 
        key={item.ID}
        className={item.ID}
        show={modal} 
        onClose={setModal}
        color={color}
        {...props}
    >
        <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
          {
              inputs && inputs.map((input)=>{
             return(
               <>
              <CLabel className='col-md-8' htmlFor={input.id}>{input.label}</CLabel>
              
              {input.type !='textarea' && input.type !='select' &&<CInput type = {input.type} id={input.id+Math.random()} id_={input.id}  className='col-md-8'  value={formParams==undefined?'':input.type=='file' ? '':formParams[input.id]} 
               onChange={(s)=>{
                 console.log(formParams);
                setFormParams({...formParams,...{[s.target.getAttribute("id_")]: input.type== 'checkbox'?s.target.checked:s.target.value}})
                }}
              />}
              {input.type =='textarea' &&<CTextarea rows={6} type = {input.type} id={input.id+Math.random()} id_={input.id}  className='col-md-8'  value={formParams==undefined?'':input.type=='file' ? '':formParams[input.id]} 
               onChange={(s)=>{
                 console.log(formParams);
                setFormParams({...formParams,...{[s.target.getAttribute("id_")]:s.target.value}})
                }}
               
              />}
               {input.type =='select' && <CSelect custom id={input.id+Math.random()} id_={input.id}  className='col-md-8'  value={formParams==undefined?'':input.type=='file' ? '':formParams[input.id]} 
              
               onChange={(s)=>{
                  console.log(formParams);
                 setFormParams({...formParams,...{[s.target.getAttribute("id_")]: input.type== 'checkbox'?s.target.checked:s.target.value}})
                  }}>
                  {
                    input.values && input.values.map((element)=>{
                      return ( <option  className='col-md-8' key={element.id} value={element.id}>{element.value}</option>)
                    })
                  }
                  
                </CSelect>}
              </>
              )
              })
            }
          </CForm>
            
          </CModalBody>
        <CModalFooter>
        <CButton type= 'submit' color={color} onClick={()=>{
                            AuthService.createRequest(
                                okEndPoint.method,
                                okEndPoint.endPoint,
                                  null,
                                  JSON.stringify({
                                    ...formParams
                                  }),
                                )
                                  .then((response) => response.json())
                                  .then((response) => {
                                      if (response.statuscode!=200){
                                          _toast(`Something Went Wrong ${response.statusmessage}`,'error');
                                          return;
                                      }
                                      _toast(response.statusmessage,'success');
                                      history.go(0);
                                  }).catch((e)=>{
                                      _toast(e,'error');
                                   });
                        }}>{okTilte}</CButton>{' '}
        <CButton 
            color="secondary" 
            onClick={()=>{
              setModal(false)
            }}
        >{cancelTitle}</CButton>
        </CModalFooter>
    </CModal>
  </>
  )
}

export default GModal
