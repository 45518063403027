import AuthService from 'src/services/authServices';
import * as actiontypes from "./actionTypes";


const getNotificationsSuccess = (notifications) => {
    return {
        type : actiontypes.NEW_NOTIFICATIONS,
        notifications
    }
}
export const readNotification = (notification) => {
    if(notification.NotificationType === 6 || notification.NotificationType === 7){
        return (dispatch) => {
            AuthService.createRequest(
                'POST',
                'mng/readbenotification',
                null,
                JSON.stringify({
                    notificationID:notification.ID,
                    requestID:notification.RequestID
                }),
                )
                .then((response) => response.json())
                .then((response) => {
                    dispatch(getNotifications());
                });
        }
    }else{
        return (dispatch) => {
            AuthService.createRequest(
                'POST',
                'mng/readcallrequest',
                null,
                JSON.stringify({
                    notificationID:notification.ID
                }),
                )
                .then((response) => response.json())
                .then((response) => {
                    dispatch(getNotifications());
                });
        }
    }
}
export const getNotifications = () => {
    return (dispatch) => {
        AuthService.createRequest(
            'GET',
              'mng/benotifications',
              null,
              null,
            )
            .then((response) => response.json())
            .then((response) => {
                const notifications = response.responseobject?response.responseobject:[];
                dispatch(getNotificationsSuccess(notifications))
            });
    }
}
