
import CIcon from '@coreui/icons-react';
import moment from 'moment';
 const CancellederviceRequestsSeeder = {
     scopedSlots : {
          'StatusTimeStamp': (item)=>{
            return (
              <td className='text-center'>
                {moment.utc(item.StatusTimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
              </td>
          )
          },
          'location_type':
          (item)=>(
            <td>
                <CIcon  name={item.location_type== 2?"cil-phone":"cil-home"} size="2xl"/>
            </td>
          ),  
      },
      
     fields : [
          {key: 'StatusTimeStamp',label: 'Timestamp'},
          'ServiceType','Speciality',
          {key: 'ClientFullName',label: 'Name'},
          'PhoneNumber', 
          {key: 'location_type',label: 'Location Type '},
          {key: 'CurrentConditionDescription',label: 'Description'},
          {key: 'UserFullName',label: 'Updated By'},
     ],
      
     intialTableParams:{
        page:1,
        pages:1,
        length:10
        },
        
      params : {},
      
     dataendPoint: `ServiceRequest/getallcancelled`,
     dataendPointMethod: 'POST',
     headline: 'Cancelled Service Requests'
      
      

};

export default CancellederviceRequestsSeeder;