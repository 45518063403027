import React, { useState, useEffect } from 'react'; 
import AuthService from '../services/authServices'
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CSelect,
    CRow,
  
} from '@coreui/react'
import ServiceRequestsService from 'src/services/ServiceRequestsService';
import {_toast} from 'src/services/utils';


const loadSTs =ServiceRequestsService.loadSTs;
const loadSpecs =ServiceRequestsService.loadSpecs;
const loadSps =ServiceRequestsService.loadSps;


const SRConfirmModal = ({modal,setModal,serviceRequest,...props}) => {
  const [serviceTypes, setServiceTypes] = useState([]);
  const [specialites, setSpecialites] = useState([]);
  const [sps, setSps] = useState([]);
  let params={
    RequestID:serviceRequest.ID,
    ProviderID:'',
    RequestDate:serviceRequest.SelectedDate.substr(0,10),
    RequestTime:serviceRequest.SelectedTime.substr(0,5),
    RequestAddress:serviceRequest.Address,
    RequestDescription:serviceRequest.CurrentConditionDescription,
  };

  useEffect(() => {
    console.log(serviceRequest);
    
    loadSTs(setServiceTypes);
    
    loadSpecs(serviceRequest.ServiceTypeID,setSpecialites);

    
    loadSps(serviceRequest.SpecialityID,setSps);
    
  }, [])
  return (
   
    <CModal 
        show={modal} 
        onClose={setModal}
        color={"success"}
        size="lg"
    >
        <CModalHeader closeButton>
        <CModalTitle>Confirm Request</CModalTitle>
        </CModalHeader>
        <CModalBody>
        <>
          <CRow>
              <CCol xs="12" sm="12">
                <CCard>
                 
                  <CCardBody>
                  <CForm>
                    <CRow>
                      <CCol xs="12" sm="4">
                        <CFormGroup>
                          <CLabel htmlFor="stype">Service Type</CLabel>
                          <CSelect custom name="stype" id="stype" defaultValue={serviceRequest.ServiceTypeID} onChange={(s)=>{
                            loadSpecs(s.target.value,setSpecialites);
                            params.ServiceTypeID=s.target.value;
                            }}>
                            {
                              serviceTypes && serviceTypes.map((element)=>{
                                return ( <option key={element.ID} value={element.ID}>{element.ServiceType}</option>)
                              })
                            }
                            
                          </CSelect>
                        </CFormGroup>
                      </CCol>
                  
                      <CCol xs="12" sm="4" >
                        <CFormGroup>
                          <CLabel htmlFor="specitlity">Specialities</CLabel>
                          <CSelect custom name="specitlity" id="specitlity"  defaultValue={serviceRequest.SpecialityID} onChange={(s)=>{
                            loadSps(s.target.value,setSps);
                            params.SpecialityID=s.target.value;
                            }}>
                          {
                            specialites&&  specialites.map((element)=>{
                                return ( <option key={element.ID} value={element.ID}>{element.Speciality}</option>)
                              })
                            }
                          </CSelect>
                        </CFormGroup>
                      </CCol>
                      <CCol xs="12" sm="4" >
                        <CFormGroup>
                          <CLabel htmlFor="serviceprovider">Service Provider</CLabel>
                          <CSelect custom name="serviceprovider" id="serviceprovider"   onChange={(s)=>{
                            params.ProviderID=s.target.value;
                            }}>
                                                  <option value="" selected hidden disabled> </option> 
                          {
                            sps&&  sps.map((element)=>{
                                return ( <option key={element.ID} value={element.ID}>{element.ServiceProviderFullName}</option>)
                              })
                            }
                          </CSelect>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs="6" >
                        <CFormGroup>
                          <CLabel htmlFor="visitdate">Visit Date</CLabel>
                          <CInput type="date" id="visitdate" name="visitdate" defaultValue={serviceRequest.SelectedDate.substr(0,10)} onchange={(s)=>{
                            params.RequestDate=s.target.value;
                            console.log(params);
                            }} />
                        </CFormGroup>
                      </CCol>
                      <CCol xs="6" >
                        <CFormGroup>
                          <CLabel htmlFor="visittime">Visit time</CLabel>
                          <CInput type="time" id="visittime" name="visittime"  onChange={(s)=>{
                            params.RequestTime=s.target.value;
                            console.log(params);
                            }} />
                        </CFormGroup>
                      </CCol>
                      
                    </CRow>
                    <CRow>
                      <CCol xs="12" sm="12" >
                        <CFormGroup>
                          <CLabel htmlFor="description">Description</CLabel>
                          <CInput id="description"  defaultValue={serviceRequest.CurrentConditionDescription}  onChange={(s)=>{
                            params.RequestDescription=s.target.value;
                            console.log(params);
                            }} />
                        </CFormGroup>
                      </CCol>
                      <CCol xs="12" sm="12" >
                        <CFormGroup>
                          <CLabel htmlFor="address">Visit Address</CLabel>
                          <CInput id="address" defaultValue={serviceRequest.Address}   onChange={(s)=>{
                            params.RequestAddress=s.target.value;
                          
                            console.log(params);
                            }} />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
          </CRow>
          
          </>
        </CModalBody>
        <CModalFooter>
        <CButton color={'success'} onClick={()=>{
                          AuthService.createRequest(
                              'POST',
                                'ServiceRequest/updateservicerequest',
                                null,
                                JSON.stringify({
                                  ...params
                                }),
                              )
                                .then((response) => response.json())
                                .then((response) => {
                                    if (response.statuscode!=200){
                                        _toast(`Something Went Wrong ${response.statusmessage}`,'error');
                                        return;
                                    }
                                    _toast(response.statusmessage,'success');
                                    window.location.assign(`../../#/ServiceRequests/ConfirmedRequests/`);
                                }).catch((e)=>{
                                    _toast(e,'error');
                                 });
                      }}>Confirm Request and move to in progress</CButton>{' '}
        </CModalFooter>
    </CModal>
  )
}

export default SRConfirmModal
