import React, { useEffect, useState } from 'react'
import {
  CSelect
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import AuthService , {isAuthenticated} from 'src/services/authServices'
import { useHistory, useLocation } from 'react-router-dom'
import {_toast} from 'src/services/utils';

const CountriesDropdown = () => {
  const [countries, setCountries]= useState([]);
  const history = useHistory();
  const [selectedCountry, setSelectedCountry]= useState(localStorage.getItem('CountrySelected') );
  const loadData = () => {
    AuthService.createRequest(
      'GET',
        'mng/countries',
        null,
        JSON.stringify({}),
      )
        .then((response) => response.json())
        .then((response) => {
          setCountries(response.responseobject);
        }).catch((e)=>{
          _toast(e,'error');
        });
  }
  useEffect(() => {
    if(isAuthenticated()) {
      loadData();
    }
  }, []);
  return (
    <CSelect custom name="country_id" id="country_id" value={selectedCountry} onChange = {(e)=>{
        setSelectedCountry(e.target.value);
        localStorage.setItem('CountrySelected', e.target.value);
        history.go(0);
    }}>
       { countries && countries.map( (country)=>
            {
             return(
              <option value={country.Id} key={country.Id}>{country.Name}</option>
                 )
            }
       )
       }
      </CSelect>
      
  )}

export default CountriesDropdown
