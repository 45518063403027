import AuthService from "./authServices";
import {_toast} from 'src/services/utils';


class ServiceRequestsService {
    static async loadSpecs  (id,setSpecialites){
        AuthService.createRequest(
            'POST',
            `Service/spec?ServiceTypeID=${id}&LangID=1`,
            null,
            JSON.stringify({
              ServiceTypeID:id,
              LangID:1
            }),
          )
            .then((response) => response.json())
            .then((response) => {
              setSpecialites(response.responseobject);
            }).catch((e)=>{
                _toast(e,'error');
            });
    
      }
    
      static async loadSTs  (setServiceTypes){
        
        AuthService.createRequest(
          'GET',
          `Service/st?LangID=1`,
          null,
          JSON.stringify({
          }),
        )
          .then((response) => response.json())
          .then((response) => {
            setServiceTypes(response.responseobject);
          }).catch((e)=>{
              _toast(e,'error');
          });
    
      }
      static async loadSps  (id,setSps){
        AuthService.createRequest(
            'POST',
            `Providers/allServiceProvidersForClient`,
            null,
            JSON.stringify({
              SpecialityID:id,
              LangID:1
            }),
          )
            .then((response) => response.json())
            .then((response) => {
                setSps(response.responseobject);
            }).catch((e)=>{
                _toast(e,'error');
            });
    
      }
      
}

export default ServiceRequestsService;