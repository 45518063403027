import React , {useRef,useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import * as actions from "src/store/actions";
import { useMediaPredicate } from "react-media-hook";

// sidebar nav config
import navigation from './_nav'
function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable callback ref
  const innerRef = useRef(); // returned to client, who sets the "border" element
  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => { callbackRef.current = callback; });
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (innerRef.current && callbackRef.current && 
        !innerRef.current.contains(e.target)
      ) callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener
      
  return innerRef; // convenience for client (doesn't need to init ref himself) 
}
const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.common.sidebarShow);
  const notLarge = useMediaPredicate("(max-width: 991.98px)");
  const innerRef = useOuterClick(e => {
    if(show&&notLarge) dispatch(actions.setSlider(false));
  });
  useEffect(()=>{
    if(notLarge)dispatch(actions.setSlider(false));
  },[]);
  return (
      <CSidebar
        show={show}
      >
        <div ref={innerRef}>
          <CSidebarBrand className="bg-white d-md-down-none" to="/">
              <CImg   style={{ width: '50%' }}src="https://hospitalia-public-assets.s3.eu-central-1.amazonaws.com/public/hospitalia-logo.png"></CImg>
          </CSidebarBrand>
          <CSidebarNav>

            <CCreateElement
              items={navigation}
              components={{
                CSidebarNavDivider,
                CSidebarNavDropdown,
                CSidebarNavItem,
                CSidebarNavTitle
              }}
            />
          </CSidebarNav>
          <CSidebarMinimizer className="c-d-md-down-none"/>
        </div>
      </CSidebar>
  )
}

export default React.memo(TheSidebar)
