import * as actionTypes from "src/store/actions/actionTypes";

const defaultState = {
  sidebarShow: 'responsive',
  loggedIn: false,
  termsAndConditions: null
};

export default (state = defaultState, { type, ...rest }) => {
  switch (type) {
    case actionTypes.SET_SLIDER:
      return {...state, ...rest }
    case actionTypes.TERMS_CONDITIONS:
      return {
          ...state,
          termsAndConditions : rest.termsAndConditions
      } 
    case actionTypes.LOGIN_SUCCESS:
      return {...state , loggedIn:true}
    default:
      return state
  }
};
