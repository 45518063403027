
import CIcon from '@coreui/icons-react'
import {
  CButton,

} from '@coreui/react'
import moment from 'moment';
const CompletedServiceRequestsSeeder = {
  scopedSlots: {
    'StatusTimeStamp': (item)=>{
      return (
        <td className='text-center'>
          {moment.utc(item.StatusTimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
        </td>
    )
    },
    'Location':
      (item) => (
        <td>
          <CIcon name="cil-location-pin" size="2xl" onClick={() => {
            window.open(`https://maps.google.com/?q=${item.Location_Lat},${item.Location_Long}`, '_blank');

          }
          } />
        </td>
      ),
    'PayementMethod':
      (item) => (
        <td>
          <CIcon name={item.PayementMethod == 2 ? "cil-credit-card" : "cil-dollar"} size="2xl" />
        </td>
      ),
    'PromoCodeTitle':
      (item) => (
        <td className='text-center'>
          {item.PromoCodeTitle ? item.PromoCodeTitle : '-----'}
        </td>
      ),
    'location_type':
      (item) => (
        <td>
          <CIcon  name={item.location_type == 2 ? "cil-phone" : "cil-home"} size="2xl" />
        </td>
      ),


  },

  fields: [
    { key: 'StatusTimeStamp', label: 'Timestamp' },
    'ServiceType', 'Speciality',
    { key: 'ClientFullName', label: 'Name' },
    'PhoneNumber',
    { key: 'CurrentConditionDescription', label: 'Description' },
    'Fees',
    { key: 'location_type', label: 'Location Type ' },
    'PromoCodeTitle',
    , 'SelectedDate', 'SelectedTime', 'Address', 'Location',
    "PayementMethod",
    { key: 'UserFullName', label: 'Updaed By' },
  ],

  intialTableParams: {
    page: 1,
    pages: 1,
    length: 10
  },

  params: {},

  dataendPoint: `ServiceRequest/getallcompleted`,
  dataendPointMethod: 'POST',
  headline: 'Completed Service Requests'



};

export default CompletedServiceRequestsSeeder;