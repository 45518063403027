import React from 'react';

import NewServiceRequestsSeeder from '../viewSeeders/ServiceRequests/newServiceRequest';
import ConfirmedServiceRequestsSeeder from '../viewSeeders/ServiceRequests/confirmedServiceRequest';
import CompletedServiceRequestsSeeder from '../viewSeeders/ServiceRequests/completedServiceRequest';
import RatedServiceRequestsSeeder from '../viewSeeders/ServiceRequests/ratedServiceRequest';
import CancellederviceRequestsSeeder from '../viewSeeders/ServiceRequests/cancelledServiceRequest';


import PendingCallRequestsSeeder from '../viewSeeders/ServiceRequests/PendingCallRequests';
import CompletedCallRequestsSeeder from '../viewSeeders/ServiceRequests/CompletedCallRequests';

import CustomerListSeeder from '../viewSeeders/Customers/CustomerListSeeder'
import TermsAndConditions from '../views/sideViews/TermsAndConditions';

/**
 * Service Requests Components 
 */

const CallRequests = React.lazy(() => import('../views/sideViews/CallRequests/CallRequests'));
const ServiceRequests = React.lazy(() => import('../views/sideViews/CallRequests/ServiceRequest'));
const CustomerList = React.lazy(() => import('../views/sideViews/Customers/CustomerList'));
const AdminList = React.lazy(() => import('../views/sideViews/Admins/AdminList'));
const SliderImageIndex = React.lazy(() => import('../views/sideViews/Configurations/SliderImageIndex'));

const PushNotificationsList = React.lazy(() => import('../views/sideViews/Notifications/PushNotificationsList'));
const BENotificationsList = React.lazy(() => import('../views/sideViews/Notifications/BENotificationsList'));
const ServiceTypesList = React.lazy(() => import('../views/sideViews/ServiceTypes/ServiceTypesList'));
const ServiceProviderList = React.lazy(() => import('../views/sideViews/ServiceProvider/ServiceProviderList'));
const SpecialitiesList = React.lazy(() => import('../views/sideViews/ServiceTypes/SpecialitiesList'));

const PromoCodeList = React.lazy(() => import('../views/sideViews/Configurations/PromoCodeIndex'));
const DashboardComponent = React.lazy(() => import('../views/dashboard/Dashboard'));


const InsServiceComponent = React.lazy(() => import('../views/sideViews/Installment/ServiceList'));
const InsCenterComponent = React.lazy(() => import('../views/sideViews/Installment/CenterList'));
const InsOperationComponent = React.lazy(() => import('../views/sideViews/Installment/OperationList'));
const InsOpAvailabityComponent = React.lazy(() => import('../views/sideViews/Installment/OpAvailabity'));
const InsOpRequets = React.lazy(() => import('../views/sideViews/Installment/RequestList'));


const routes = [
  // dashboard
  { path: '/dashboard', meta:{ auth: true , notAuth:false } ,name: 'Dashboard', component: DashboardComponent },
  /**
   * Service Types
   */
  { path: '/ServiceTypes/ServiceTypes', meta:{ auth: true , notAuth:false } ,name: 'Service Types', component: ServiceTypesList },
  { path: '/ServiceProviders/Index', meta:{ auth: true , notAuth:false } , name: 'Service Providers', component: ServiceProviderList },
  { path: '/ServiceTypes/Specialities', meta:{ auth: true , notAuth:false } ,exact: true,name: 'Specialiteis', component: SpecialitiesList },
  { path: '/ServiceTypes/Specialities/:id', meta:{ auth: true , notAuth:false } , exact: true, name: 'Specialiteis', component: SpecialitiesList },



  /**
   * Service Requests routes
   */
  { path: '/ServiceRequests/NewRequests', meta:{ auth: true , notAuth:false } ,name: 'NewServiceRequests', component: ServiceRequests , seeder: NewServiceRequestsSeeder},
  { path: '/ServiceRequests/ConfirmedRequests', meta:{ auth: true , notAuth:false } ,name: 'ConfirmedServiceRequests', component: ServiceRequests , seeder: ConfirmedServiceRequestsSeeder},
  { path: '/ServiceRequests/CompletedRequests', meta:{ auth: true , notAuth:false } ,name: 'CompletedServiceRequests', component: ServiceRequests , seeder: CompletedServiceRequestsSeeder},
  { path: '/ServiceRequests/RatedRequests', meta:{ auth: true , notAuth:false } , name: 'RatedServiceRequests', component: ServiceRequests , seeder: RatedServiceRequestsSeeder},
  { path: '/ServiceRequests/CancelledRequests', meta:{ auth: true , notAuth:false } ,name: 'CancelledServiceRequests', component: ServiceRequests , seeder: CancellederviceRequestsSeeder},
  /**
   * Call Requests Routes
   */
  { path: '/ServiceRequests/CallRequests', meta:{ auth: true , notAuth:false } , name: 'PendingCallRequests', component: CallRequests , seeder: PendingCallRequestsSeeder},
  { path: '/ServiceRequests/CompletedCallRequests', meta:{ auth: true , notAuth:false } , name: 'CompletedCallRequests_', component: CallRequests , seeder: CompletedCallRequestsSeeder},

  /**
   * Customers List
   */
  { path: '/Customers/Index', name: 'CustomerList', meta:{ auth: true , notAuth:false } , component: CustomerList , seeder: CustomerListSeeder},

  /**
   * Customers List
   */
  { path: '/Users/Index', name: 'CustomerList', meta:{ auth: true , notAuth:false } , component: AdminList },

  /**
   * Notifications List
   */
  { path: '/Management/BackendNotifications', name: 'Backend Notifications', meta:{ auth: true , notAuth:false } ,component: BENotificationsList },
  { path: '/Management/PushNotifications', name: 'Push Notifications', meta:{ auth: true , notAuth:false } , component: PushNotificationsList },

  /**
   * Installement
   */
   { path: '/installment/services/index', meta:{ auth: true , notAuth:false } ,name: 'Installment Services', component: InsServiceComponent },
   { path: '/installment/centers/index', meta:{ auth: true , notAuth:false } , name: 'Installment Centers', component: InsCenterComponent },
   { path: '/installment/Operations/index', meta:{ auth: true , notAuth:false } ,exact: true,name: 'Installment Operations', component: InsOperationComponent },
   { path: '/installment/Operations/availabilty', meta:{ auth: true , notAuth:false } ,exact: true,name: 'Availabity of Operations', component: InsOpAvailabityComponent },
   { path: '/installment/requests/index', meta:{ auth: true , notAuth:false } ,exact: true,name: 'Operation Requets', component: InsOpRequets },

   /**
   * Configurations List
   */
  { path: '/Attachrments/Index', name: 'CustomerList', meta:{ auth: true , notAuth:false },component: SliderImageIndex },
  { path: '/Management/TermsAndConditions', name: 'Terms & Conditions', meta:{ auth: true , notAuth:false },component: TermsAndConditions },
  { path: '/PromoCodes/Index', name: 'PromoCodes', component: PromoCodeList ,meta:{ auth: true , notAuth:false }},
  
];

export default routes;
