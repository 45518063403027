import CIcon from '@coreui/icons-react'
import moment from 'moment';
const RatedServiceRequestsSeeder = {
  scopedSlots: {
    'StatusTimeStamp': (item)=>{
      return (
        <td className='text-center'>
          {moment.utc(item.StatusTimeStamp).local().format('YYYY-MM-DD HH:mm:ss')}
        </td>
    )
    },
    'PayementMethod':
      (item) => (
        <td>
          <CIcon name={item.PayementMethod == 2 ? "cil-credit-card" : "cil-dollar"} size="2xl" />
        </td>
      ),
    'location_type':
      (item) => (
        <td>
          <CIcon name={item.location_type == 2 ? "cil-phone" : "cil-home"} size="2xl" />
        </td>
      ),
    'PromoCodeTitle':
      (item) => (
        <td className='text-center'>
          {item.PromoCodeTitle ? item.PromoCodeTitle : '-----'}
        </td>
      )
  },

  fields: [
    { key: 'StatusTimeStamp', label: 'Timestamp' },
    'ServiceType', 'Speciality',
    { key: 'ServiceProvider', label: 'ServiceProvider' },
    { key: 'ClientFullName', label: 'Client' },
    'PhoneNumber',
    { key: 'location_type', label: 'Location Type ' },
    'PromoCodeTitle',
    { key: 'CurrentConditionDescription', label: 'Description' },
    "PayementMethod",
    { key: 'UserRate', label: 'Rate' },
    { key: 'RateComment', label: 'Comment' },

  ],

  intialTableParams: {
    page: 1,
    pages: 1,
    length: 10
  },

  params: {},

  dataendPoint: `ServiceRequest/getallrated`,
  dataendPointMethod: 'POST',
  headline: 'Rated Service Requests'



};

export default RatedServiceRequestsSeeder;